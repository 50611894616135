<template>
  <div
    style="min-height: 100%;"
    class="c-d-flex ui-flex-col c-bg-primary-200"
    :style="{ backgroundColor: color }"
  >
    <!-- Logo e stepper -->
    <div class="c-container h-header">
      <div class="c-row ui-pt-8">
        <!-- Logo Estratégia -->
        <div class="c-col-2 sm:c-col-2 md:c-col-2">
          <estrategia-logo :icon-path="partner.iconPath" />
        </div>

        <!-- Stepper -->
        <div class="c-col-2 sm:c-col">
          <p-stepper />
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="bg-image-lateral ui-flex-1 c-container c-d-flex c-center">
      <div class="ui-w-full c-row c-horizontal-center">
        <router-view class="c-col-full ui-rounded-lg c-bg-white ui-px-4 md:ui-px-8 ui-py-8 ui-overflow-hidden" />
      </div>
    </div>

    <div class="ui-w-full c-d-flex c-horizontal-center">
      <div class="bg-image-bottom" />
    </div>
  </div>
</template>

<script>
import PStepper from '@/components/p-stepper';
import EstrategiaLogo from '@/components/estrategia-logo';

export default {
  components: { EstrategiaLogo, PStepper },
  data: () => {
    return {
      partner: {
        color: null,
        iconPath: '',
      },
    };
  },
  computed: {
    color() {
      return this.partner.color;
    },
  },
  created() {
    this.handlePreviousLoginPartner();
    this.getPartnerBySlug();
  },
  methods: {
    getPartnerBySlug() {
      if (this.$route.query.parceiro)
      {
        this.$services.bff.getPartnerBySlug(this.$route.query.parceiro).then((partner) => {
          if (partner)
          {
            this.partner.color = partner?.color;
            this.partner.iconPath = partner?.icon_url;
          }
        });
      }
    },
    handlePreviousLoginPartner() {
      const previousPartner = localStorage.getItem('partner@estrategia');
      const url = window.location.origin;
      if (!this.$route.query.parceiro && previousPartner && url.includes('parceiro')) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            parceiro: previousPartner,
          },
        });

      }
    },
  },
};
</script>

<style scoped lang="scss">
@include c-mq(sm) {
  .bg-image-lateral {
    background-size: 407px; /* medida do figma */
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/register-illustration.svg");
    background-position: 100%;
  }
}

.bg-image-bottom {
  width: 278px; /* medida do figma */
  height: 45vw;
  background-image: url("~@/assets/images/register-illustration.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @include c-mq(sm) {
    display: none;
  }
}

.h-header {
  height: var(--header-height);
}
</style>
